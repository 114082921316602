<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_edit_albo">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifica registrazione all'albo</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div
            class="mx-auto my-auto text-center py-5 my-5"
            v-if="isLoadingDetailQual"
          >
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div class="modal-body" v-if="qualificaProps[0]">
            <div class="card-body p-6">
              <div class="alert alert-danger" role="alert" v-if="alertCampiObb">
                Compila i campi obbligatori
              </div>
              <div class="ps-5 pe-5">
                <Field
                  name="id"
                  type="hidden"
                  class="form-control"
                  placeholder=""
                  aria-label=""
                  v-model="qualificaProps[0].id"
                />
                <div class="row pb-4">
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-600">Persona</label>
                    <div class="dp__input_wrap">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        disabled
                        v-model="qualificaProps[0].nominativo_completo"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Settore</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      disabled
                      v-model="qualificaProps[0].id_settore"
                    />
                  </div>
                  <!-- <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Verifica</label>
                    <SelectInputLabel
                      :options="trueFalseLookup"
                      name="verifica"
                      placeholder="-"
                      :value="
                        qualificaProps[0].verifica == ''
                          ? null
                          : qualificaProps[0].verifica
                      "
                      @changeSelect="setVerifica($event)"
                    />
                  </div> -->
                </div>
                <div class="row pb-4">
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-600">Albo</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      disabled
                      v-model="qualificaProps[0].tipo_albo"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-600">Categoria</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      disabled
                      v-model="qualificaProps[0].categoria"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-600">Qualifica*</label>
                    <SelectInputLabel
                      :options="qualificheAlbo"
                      name="id_Qualifica"
                      placeholder=""
                      :value="qualificaProps[0].id_qualifica"
                      @changeSelect="setIdQualifica($event)"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Codice DIR</label>
                    <Field
                      name="codice_Dir"
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="qualificaProps[0].codice_dir"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Codice SNM</label>
                    <Field
                      name="codice_Snm"
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="qualificaProps[0].codice_snm"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Numero albo</label>
                    <Field
                      name="numero_Albo"
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="qualificaProps[0].numero_albo"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600"
                      >Data acquisizione*</label
                    >
                    <Datepicker
                      v-model="qualificaProps[0].data_acq"
                      @update:modelValue="
                        $event
                          ? (qualificaProps[0].data_acq =
                              fromUTCtoISODate($event))
                          : ''
                      "
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                    />
                    <Field
                      name="data_Acq"
                      type="hidden"
                      v-model="qualificaProps[0].data_acq"
                      class="form-control"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="data_acq"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Data decorrenza</label>
                    <Datepicker
                      v-model="qualificaProps[0].data_dec"
                      @update:modelValue="
                        $event
                          ? (qualificaProps[0].data_dec =
                              fromUTCtoISODate($event))
                          : ''
                      "
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      disabled
                    />
                    <Field
                      name="data_Dec"
                      type="hidden"
                      v-model="qualificaProps[0].data_dec"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Data fine</label>
                    <Datepicker
                      v-model="qualificaProps[0].data_fine"
                      @update:modelValue="
                        $event
                          ? (qualificaProps[0].data_fine =
                              fromUTCtoISODate($event))
                          : ''
                      "
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                    />
                    <Field
                      name="data_Fine"
                      type="hidden"
                      v-model="qualificaProps[0].data_fine"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Note</label>
                    <Field
                      type="text"
                      as="textarea"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      name="note"
                      v-model="qualificaProps[0].note"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEdit"
              @click="$emit('resetQualifica')"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              @click="$emit('resetQualifica')"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { ref, watch, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInputLabel from "@/components/components-fit/utility/inputs/SelectInput_v02_label.vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { fromUTCtoISODate } from "@/composables/formatDate";
import { editQualifica } from "@/requests/alboRequests";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "edit-albo",
  components: {
    Datepicker,
    SelectInputLabel,
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  emits: ["refreshAlboList", "resetQualifica"],
  props: {
    qualifica: {
      type: Object,
    },
    isLoadingDetailQual: {
      type: Boolean,
    },
  },
  setup(props) {
    const qualificaProps = ref(props.qualifica);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );
    const keys = ref("atcq");
    if (!albi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const categorieAlbo = ref([]);
    const qualificheAlbo = ref([]);
    const resetQualifiche = () => {
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
    };

    const setIdQualifica = (event) => {
      qualificaProps.value[0].id_qualifica = parseInt(event.value);
      qualificaProps.value[0].qualifica = event.name;
    };
    const setVerifica = (event) => {
      qualificaProps.value[0].verifica = event.value;
    };

    const rowsToSkip = computed(() => store.getters.rowsToSkipAlbo);
    const fetchRows = computed(() => store.getters.fetchRowsAlbo);
    const sortColumn = computed(() => store.getters.sortColumnAlbo);
    const sortOrder = computed(() => store.getters.sortOrderAlbo);

    const id_Tipo_Albo = computed(() => store.getters.idTipoAlbo);
    const id_Categoria = computed(() => store.getters.idCategoriaAlbo);
    const id_Qualifica = computed(() => store.getters.idQualificaAlbo);
    const codice_Snm = computed(() => store.getters.codiceSnmAlbo);
    const data_Acq_From = computed(() => store.getters.dataAcqDaAlbo);
    const data_Acq_To = computed(() => store.getters.dataAcqAAlbo);
    const data_Dec_From = computed(() => store.getters.dataDecDaAlbo);
    const data_Dec_To = computed(() => store.getters.dataDecAAlbo);
    const data_Fine_From = computed(() => store.getters.dataFineDaAlbo);
    const data_Fine_To = computed(() => store.getters.dataFineAAlbo);
    const nome = computed(() => store.getters.nomeAlbo);
    const cognome = computed(() => store.getters.cognomeAlbo);
    const id_Societa = computed(() => store.getters.idSocietaAlbo);
    const verifica = computed(() => store.getters.verificaAlbo);
    const id_Stato = computed(() => store.getters.statoAlbo);
    const id_Stato_Utenza = computed(() => store.getters.statoUtenzaAlbo);

    const getAlboList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Tipo_Albo: id_Tipo_Albo.value,
          id_Categoria: id_Categoria.value,
          id_Qualifica: id_Qualifica.value,
          codice_Snm: codice_Snm.value,
          data_Acq_From: data_Acq_From.value,
          data_Acq_To: data_Acq_To.value,
          data_Dec_From: data_Dec_From.value,
          data_Dec_To: data_Dec_To.value,
          data_Fine_From: data_Fine_From.value,
          data_Fine_To: data_Fine_To.value,
          nome: nome.value,
          cognome: cognome.value,
          id_Societa: id_Societa.value,
          verifica: verifica.value,
          id_Stato: id_Stato.value,
          id_Stato_Utenza: id_Stato_Utenza.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.ALBO_LIST,
        itemName: "albo_list",
      });
    };
    const schema = yup.object().shape({
      id_Qualifica: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_Acq: yup.string().required("Obbligatorio").nullable(),
      data_Dec: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      const responseAdd = ref({});
      let messaggioResponse;
      responseAdd.value = editQualifica(values);
      messaggioResponse = "Qualifica modificata correttamente";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          getAlboList();
          document.getElementById("closeModalEdit").click();
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const alertCampiObb = ref(false);
    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    watch(
      () => props.qualifica,
      (newvalue) => {
        if (newvalue) {
          qualificaProps.value = newvalue;
          resetQualifiche();
          if (qualificaProps.value) {
            albi_tipo.value.forEach((element) => {
              if (element.id === qualificaProps.value[0].id_tipo_albo) {
                element.categorie.forEach((cat) => {
                  categorieAlbo.value.push(cat);
                });
              }
            });
            categorieAlbo.value.forEach((element) => {
              if (element.id === qualificaProps.value[0].id_categoria) {
                element.qualifiche.forEach((qual) => {
                  qualificheAlbo.value.push(qual);
                });
              }
            });
          }
        }
      }
    );
    return {
      qualificaProps,
      qualificheAlbo,
      trueFalseLookup: [
        { id: "t", label: "Si" },
        { id: "f", label: "No" },
      ],
      setIdQualifica,
      setVerifica,
      fromUTCtoISODate,
      editQualifica,
      schema,
      onSubmit,
      onInvalidSubmit,
      alertCampiObb,
      resetQualifiche,
      format,
      disableButton,
      isLoading,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
