<template>
  <div>
    <FilterAlbo
      @getAlboList="searchAlboList"
      @resetFilters="resetFilters"
      :resetted="resetted"
    />
    <TableAlbo @getAlboList="getAlboList" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterAlbo from "@/components/components-fit/albo/FiltriAlbo.vue";
import TableAlbo from "@/components/components-fit/albo/TableAlbo.vue";

export default defineComponent({
  name: "albo",
  components: {
    FilterAlbo,
    TableAlbo,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Albo", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipAlbo);
    const fetchRows = computed(() => store.getters.fetchRowsAlbo);
    const sortColumn = computed(() => store.getters.sortColumnAlbo);
    const sortOrder = computed(() => store.getters.sortOrderAlbo);

    const id_Tipo_Albo = computed(() => store.getters.idTipoAlbo);
    const id_Categoria = computed(() => store.getters.idCategoriaAlbo);
    const id_Qualifica = computed(() => store.getters.idQualificaAlbo);
    const codice_Snm = computed(() => store.getters.codiceSnmAlbo);
    const data_Acq_From = computed(() => store.getters.dataAcqDaAlbo);
    const data_Acq_To = computed(() => store.getters.dataAcqAAlbo);
    const data_Dec_From = computed(() => store.getters.dataDecDaAlbo);
    const data_Dec_To = computed(() => store.getters.dataDecAAlbo);
    const data_Fine_From = computed(() => store.getters.dataFineDaAlbo);
    const data_Fine_To = computed(() => store.getters.dataFineAAlbo);
    const nome = computed(() => store.getters.nomeAlbo);
    const cognome = computed(() => store.getters.cognomeAlbo);
    const id_Societa = computed(() => store.getters.idSocietaAlbo);
    const verifica = computed(() => store.getters.verificaAlbo);
    const id_Stato = computed(() => store.getters.statoAlbo);
    const id_Stato_Utenza = computed(() => store.getters.statoUtenzaAlbo);
    const in_regola_tesseramento = computed(
      () => store.getters.statoTesseramentoAlbo
    );
    const in_regola_aggiornamento = computed(
      () => store.getters.statoAggiornamentoAlbo
    );
    const ultimo_pagamento_albo_da = computed(
      () => store.getters.stagionePagamentoDa
    );
    const ultimo_pagamento_albo_a = computed(
      () => store.getters.stagionePagamentoA
    );
    const pagamenti_non_registrati = computed(
      () => store.getters.pagamentiNonRegistrati
    );
    const id_cr = computed(() => store.getters.idComitatoRegionaleAlbo);

    const getAlboList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Tipo_Albo: id_Tipo_Albo.value,
          id_Categoria: id_Categoria.value,
          id_Qualifica: id_Qualifica.value,
          codice_Snm: trimInput(codice_Snm.value),
          data_Acq_From: data_Acq_From.value,
          data_Acq_To: data_Acq_To.value,
          data_Dec_From: data_Dec_From.value,
          data_Dec_To: data_Dec_To.value,
          data_Fine_From: data_Fine_From.value,
          data_Fine_To: data_Fine_To.value,
          nome: trimInput(nome.value),
          cognome: trimInput(cognome.value),
          id_Societa: id_Societa.value,
          verifica: verifica.value,
          id_Stato: id_Stato.value,
          id_Stato_Utenza: id_Stato_Utenza.value,
          in_regola_tesseramento:
            in_regola_tesseramento.value == "1"
              ? 1
              : parseInt(in_regola_tesseramento.value),
          in_regola_aggiornamento:
            in_regola_aggiornamento.value == "1"
              ? 1
              : parseInt(in_regola_aggiornamento.value),
          ultimo_pagamento_albo_da: ultimo_pagamento_albo_da.value,
          ultimo_pagamento_albo_a: ultimo_pagamento_albo_a.value,
          pagamenti_non_registrati: pagamenti_non_registrati.value,
          id_cr: id_cr.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.ALBO_LIST,
        itemName: "albo_list",
      });
    };
    const resetted = ref(false);
    getAlboList();

    const resetFilters = () => {
      store.commit("resetFiltersAlbo");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getAlboList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedalbo_list")
    );

    const searchAlboList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAlbo");
      getAlboList();
    };

    return {
      getAlboList,
      resetFilters,
      resetted,
      searchAlboList,
    };
  },
});
</script>
