import services from "../axios/dbManag";

export const addAlbo = (albo) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/add", {
      ...albo,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editListAlbo = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/edit-list", {
      id_Persona: id_Persona,
    })
    .then((res) => {
      return res.data.results;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewQualificaAlbo = (id) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/view", {
      id: id,
    })
    .then((res) => {
      return res.data.results;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editQualifica = (qualifica) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/edit", {
      ...qualifica,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteQualificaAlbo = (id, id_Persona, id_Albo_Qualifica) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/del", {
      id: id,
      id_Persona: id_Persona,
      id_Albo_Qualifica: id_Albo_Qualifica,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteQualificheAlbo = (id_Persona, id_Albo_Qualifica) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/del/all", {
      id_Persona: id_Persona,
      id_Albo_Qualifica: id_Albo_Qualifica,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

/* export const addPermessiSgat = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/sgat/set", {
      id_Persona: id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
 */
export const promuoviAMaestro = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/teacher/promote", {
      id_Persona: id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

/* export const aggiungiUtenza = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/user/add", {
      id_Persona: id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
}; */

export const inRegolaOnOff = (id_Persona, in_Regola) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/regola/ufficio", {
      id_Persona: id_Persona,
      in_Regola: in_Regola,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
