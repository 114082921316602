<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add-albo">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Nuova registrazione all'albo</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="alert alert-danger" role="alert" v-if="alertCampiObb">
                Compila i campi obbligatori
              </div>
              <div class="ps-5 pe-5">
                <form autocomplete="off">
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold">Tipo albo*</label>
                      <SelectInput
                        :options="albi_tipo"
                        name="id_Tipo_Albo"
                        :value="id_Tipo_Albo"
                        @changeSelect="setid_Tipo_Albo($event)"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold">Categoria*</label>
                      <SelectInput
                        :options="categorieAlbo"
                        name="id_Categoria"
                        :value="id_Categoria"
                        @changeSelect="setid_Categoria($event)"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold">Qualifica*</label>
                      <SelectInput
                        :options="qualificheAlbo"
                        name="id_Qualifica"
                        :value="id_Qualifica"
                        @changeSelect="setid_Qualifica($event)"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-sm-6 filter">
                      <div class="dp__input_wrap">
                        <label class="fw-bold">Persona</label>
                        <input
                          name="nome_persona"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          @input="
                            getTesserati($event.target.value);
                            nome_persona === ''
                              ? (id_Persona = null)
                              : (id_Persona = 0);
                          "
                          v-model="nome_persona"
                        />
                        <Field
                          name="id_Persona"
                          type="hidden"
                          v-model="id_Persona"
                          class="form-control"
                        />
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="spliceTesserati()"
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListTesserati
                        :list="tesseratiList"
                        @hideList="resetTesserati"
                        @selectedTesserato="
                          resetTesserati();
                          nome_persona = $event.name;
                          id_Persona = $event.value;
                        "
                      ></ListTesserati>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Persona"
                      />
                    </div>
                    <!--  <div class="col-md-3">
                    <label class="fw-bold">Verifica</label>
                    <SelectInput
                      :options="trueFalseLookup"
                      name="verifica"
                      :value="verifica"
                      @changeSelect="setVerifica($event)"
                    />
                  </div> -->
                    <div class="col-md-3">
                      <label class="fw-bold">Data acquisizione*</label>
                      <Datepicker
                        v-model="data_Acq"
                        @update:modelValue="
                          $event ? (data_Acq = fromUTCtoISODate($event)) : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_Acq"
                        type="hidden"
                        v-model="data_Acq"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Acq"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Data decorrenza*</label>
                      <Datepicker
                        v-model="data_Dec"
                        @update:modelValue="
                          $event ? (data_Dec = fromUTCtoISODate($event)) : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_Dec"
                        type="hidden"
                        v-model="data_Dec"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Dec"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-12">
                      <label class="fw-bold">Note</label>
                      <Field
                        name="note"
                        as="textarea"
                        type="text"
                        v-model="note"
                        class="form-control"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Chiudi
            </button>
            <button
              type="reset"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";
import { addAlbo } from "../../../requests/alboRequests";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "addAlbo",
  components: {
    ListTesserati,
    Form,
    SelectInput,
    Datepicker,
    ErrorMessage,
    Field,
    Loading,
  },
  emits: ["refreshAlboList"],
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );
    const keys = ref("atcq");
    if (!albi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const alertCampiObb = ref(false);

    const categorieAlbo = ref([]);
    const id_Tipo_Albo = ref(null);
    const setid_Tipo_Albo = (event) => {
      const selectedid_Tipo_Albo = event != "" ? parseInt(event) : null;
      id_Tipo_Albo.value = parseInt(event);
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      albi_tipo.value.forEach((element) => {
        if (element.id === selectedid_Tipo_Albo) {
          element.categorie.forEach((cat) => {
            categorieAlbo.value.push(cat);
          });
        }
      });
    };

    const qualificheAlbo = ref([]);
    const id_Categoria = ref(null);
    const setid_Categoria = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      id_Categoria.value = parseInt(event);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      categorieAlbo.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAlbo.value.push(qual);
          });
        }
      });
    };

    const resetCategorie = () => {
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
    };

    const id_Qualifica = ref(null);
    const setid_Qualifica = (event) => {
      id_Qualifica.value = parseInt(event);
    };

    const resetQualfiche = () => {
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
    };

    const data_Acq = ref(null);

    const data_Dec = ref(null);

    const verifica = ref(null);
    const setVerifica = (event) => {
      verifica.value = event;
    };

    const note = ref(null);

    const nome_persona = ref(null);
    const id_Persona = ref(null);

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_Persona.value = null;
      resetTesserati();
    };

    const schema = yup.object().shape({
      id_Persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Tipo_Albo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Categoria: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Qualifica: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_Acq: yup.string().required("Obbligatorio").nullable(),
      data_Dec: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      const responseAdd = ref({});
      let messaggioResponse;
      responseAdd.value = addAlbo(values);
      messaggioResponse = "Albo aggiunto con successo";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          resetField();
          document.getElementById("closeModal").click();
          emit("refreshAlboList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      spliceTesserati();
      id_Tipo_Albo.value = null;
      id_Categoria.value = null;
      id_Qualifica.value = null;
      verifica.value = null;
      data_Acq.value = null;
      data_Dec.value = null;
      note.value = null;
      resetCategorie();
      resetQualfiche();
    };

    return {
      albi_tipo,
      categorieAlbo,
      qualificheAlbo,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      id_Tipo_Albo,
      setid_Tipo_Albo,
      id_Categoria,
      setid_Categoria,
      id_Qualifica,
      setid_Qualifica,
      resetCategorie,
      resetQualfiche,
      verifica,
      setVerifica,
      data_Acq,
      data_Dec,
      note,
      nome_persona,
      id_Persona,
      getTesserati,
      resetTesserati,
      spliceTesserati,
      tesseratiList: computed(() => store.getters.personeSocieta),
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      alertCampiObb,
      fromUTCtoISODate,
      format,
      disableButton,
      isLoading,
    };
  },
});
</script>

<style scoped>
.dp__clear_icon {
  top: 70%;
}

.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 33%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 60%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
