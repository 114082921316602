<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_delete_qualifiche">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elimina qualifiche</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="pb-6 ps-6 d-flex">
            Tesserato:
            <h5 class="fw-bold text-uppercase ps-4 pe-4">
              {{ persona }}
            </h5>
          </div>
          <div
            class="mx-auto my-auto text-center py-5 my-5"
            v-if="isLoadingDetail"
          >
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div class="card-body" v-if="!isLoadingDetail">
            <div class="ps-5 pe-5 fw-bold">
              <div
                class="form-check row pb-4"
                v-for="qualifica in albo"
                :key="qualifica.id"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  :value="qualifica.id"
                  @click="
                    id = qualifica.id;
                    id_Persona = qualifica.id_persona;
                    id_Albo_Qualifica = qualifica.id_albo_qualifica;
                  "
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  {{ qualifica.categoria }} - {{ qualifica.qualifica }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalDeleteQualifica"
          >
            Chiudi
          </span>
          <button
            type="button"
            class="badge bg-danger rounded text-white fs-6"
            @click="deleteQualifica()"
          >
            Eimina qualifica
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { deleteQualificaAlbo } from "@/requests/alboRequests";
import { alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "delete-qualifiche",
  data() {
    return {};
  },
  components: {
    Loading,
  },
  emit: ["refreshAlboList"],
  props: {
    albo: {
      type: Object,
      required: true,
    },
    persona: {
      type: String,
      required: true,
    },
    idAlbo: {
      type: Number,
      required: true,
    },
    isLoadingDetail: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const id = ref(null);
    const id_Persona = ref(null);
    const id_Albo_Qualifica = ref(null);

    const isLoading = ref(false);

    const deleteQualifica = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La qualifica verrà eliminata definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteQualificaAlbo(
            id.value,
            id_Persona.value,
            id_Albo_Qualifica.value
          ).then((res) => {
            if (res.status == 200) {
              alertSuccess("Qualifica eliminata correttamente");
              emit("refreshAlboList");
              document.getElementById("closeModalDeleteQualifica").click();
            } else {
              const strMsg = ref(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      deleteQualifica,
      id,
      id_Persona,
      id_Albo_Qualifica,
      isLoading,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
