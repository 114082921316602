<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_select_edit_albo">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Seleziona elemento</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="pb-6 ps-6 d-flex">
            Tesserato:
            <h5 class="fw-bold text-uppercase ps-4 pe-4">
              {{ persona }}
            </h5>
          </div>
          <div
            class="mx-auto my-auto text-center py-5 my-5"
            v-if="isLoadingDetail"
          >
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div class="card-body" v-if="!isLoadingDetail">
            <div class="ps-5 pe-5 fw-bold">
              <div
                class="form-check row pb-4"
                v-for="qualifica in albo"
                :key="qualifica.id"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  :value="qualifica.id"
                  @click="id = qualifica.id"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  {{ qualifica.categoria }} - {{ qualifica.qualifica }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_edit_albo"
            @click="viewQualifica()"
          >
            Seleziona
          </span>
        </div>
      </div>
    </div>
  </div>
  <EditAlbo
    :qualifica="selectedQualifica"
    @resetQualifica="selectedQualifica = null"
    :isLoadingDetailQual="isLoadingDetailQual"
  />
</template>

<script>
import { defineComponent, ref } from "vue";
import EditAlbo from "./EditAlbo.vue";
import { viewQualificaAlbo } from "@/requests/alboRequests";

import { alertFailed } from "@/composables/swAlert";

export default defineComponent({
  name: "select-edit-elemento",
  data() {
    return {};
  },
  props: {
    albo: {
      type: Object,
      required: true,
    },
    persona: {
      type: String,
      required: true,
    },
    isLoadingDetail: {
      type: Boolean,
    },
  },
  components: { EditAlbo },
  setup() {
    const id = ref(null);
    const isLoadingDetailQual = ref(false);

    const selectedQualifica = ref({});
    const viewQualifica = () => {
      isLoadingDetailQual.value = true;
      viewQualificaAlbo(id.value).then((res) => {
        isLoadingDetailQual.value = false;
        if (res.length > 0) {
          selectedQualifica.value = res;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_edit_albo"
          );
        }
      });
    };

    return {
      viewQualifica,
      selectedQualifica,
      id,
      isLoadingDetailQual,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
