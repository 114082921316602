<template>
  <div class="modal fade" tabindex="-10" id="modal_add_utenza_albo">
    <div class="modal-dialog">
      <div class="modal-content">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading>
        <div class="modal-header">
          <h5 class="modal-title" v-if="persona">
            Crea utenza {{ persona.nominativo_completo }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <div class="row pb-4 ps-3">
              <div class="col-md-4 mb-2">
                <label class="fw-bold text-gray-600">Username</label>
                <input
                  name="username"
                  type="text"
                  class="form-control"
                  v-model="username"
                />
              </div>
              <div class="col-md-4 mb-2">
                <label class="fw-bold text-gray-600"
                  >E-mail utente
                  <Popper
                    arrow
                    content="L'email di accesso al sistema non potrà essere cambiata."
                  >
                    <i class="bi bi-info-circle-fill text-center"></i> </Popper
                ></label>
                <input
                  name="email_utente"
                  type="text"
                  class="form-control"
                  v-model="email_utente"
                />
              </div>
              <div class="text-start mt-1 col-md-3">
                <div class="mt-5">
                  <button
                    type="button"
                    class="badge bg-light-success text-success rounded fs-6"
                    @click="addUtente()"
                  >
                    Crea utenza
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalUtenza"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";

import { addUtenza } from "@/requests/utenzeRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Popper from "vue3-popper";

export default {
  name: "add-utente-albo",
  components: { Loading, Popper },
  emits: ["refreshAlboList"],
  props: {
    persona: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const regexEmail = ref(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const isLoading = ref(false);

    const email_utente = ref(null);
    const username = ref(null);

    watch(
      () => props.persona,
      (value) => {
        if (value) {
          email_utente.value = props.persona.mail;
          username.value = props.persona.name;
        }
      }
    );

    const addUtente = () => {
      if (!email_utente.value || !username.value) {
        alertFailed("Compilare i dati richiesti");
        return false;
      } else if (regexEmail.value.test(email_utente.value)) {
        Swal.fire({
          title: "Sei Sicuro?",
          html: "Procedendo verrà creata l'utenza.",
          icon: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Annulla",
          confirmButtonText: "Crea utenza",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
            cancelButton: "btn fw-bold btn-light-warning",
          },
        }).then(async (e) => {
          if (e.isDismissed) {
            return;
          }
          if (e.isConfirmed) {
            isLoading.value = true;
            await addUtenza(
              username.value,
              email_utente.value,
              null,
              null,
              null,
              props.persona.id_persona,
              null,
              "INS"
            ).then((res) => {
              if (res.status == 200) {
                alertSuccess("Utenza creata correttamente");
                emit("refreshAlboList");
                email_utente.value = null;
                username.value = null;
                document.getElementById("closeModalUtenza").click();
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              isLoading.value = false;
            });
          }
        });
      } else {
        alertFailed("Inserire un email valida");
      }
      return false;
    };

    return {
      addUtente,
      email_utente,
      username,
      isLoading,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
