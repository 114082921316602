<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-4" v-if="!readOnly">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-albo"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>nuova registrazione
            all'albo
          </button>
          <AddAlbo @refreshAlboList="refreshAlboList" />
        </div>
      </div>
      <div class="col-4" :class="!readOnly ? 'text-center' : ''">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_albo"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            albo filtrata
          </button>
        </div>
      </div>
      <ModalEsportaAlbo />
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        albo_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="albo_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAlbo"
        @current-change="setCurrentPageAlbo"
      >
        <template v-slot:cell-nominativo_completo="{ row: data }">
          <router-link
            :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
            ><div
              class="tab-long"
              :class="data.no_qualifiche ? 'text-danger' : ''"
            >
              {{ data.nominativo_completo }}
              <span v-if="!data.nominativo_completo"> - </span>
            </div></router-link
          ></template
        >
        <template v-slot:cell-codice_snm="{ row: data }">
          <div :class="data.no_qualifiche ? 'text-danger' : ''">
            {{ data.codice_snm }}
            <span v-if="!data.codice_snm"> - </span>
          </div>
        </template>
        <template v-slot:cell-codice_dir="{ row: data }">
          <div :class="data.no_qualifiche ? 'text-danger' : ''">
            {{ data.codice_dir }}
            <span v-if="!data.codice_dir"> - </span>
          </div>
        </template>
        <template v-slot:cell-numero_albo="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            {{ qualifica.numero_albo }}
            <span v-if="!qualifica.numero_albo"> - </span>
          </div>
        </template>
        <template v-slot:cell-tipo_albo="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            {{ qualifica.albo }}
            <span v-if="!qualifica.albo"> - </span>
          </div>
        </template>
        <template v-slot:cell-categoria_qualifica="{ row: data }">
          <div
            class="tab-long-xl my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            {{ qualifica.cql }}
            <span v-if="!qualifica.cql"> - </span>
          </div>
        </template>
        <template v-slot:cell-data_acq="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <span v-if="qualifica.data_acq"
              ><div class="text-center">
                {{ getFormatDate(qualifica.data_acq) }}
              </div></span
            >
            <span v-if="!qualifica.data_acq"> - </span>
          </div>
        </template>
        <template v-slot:cell-data_decorrenza="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <span v-if="qualifica.data_dec"
              ><div class="text-center">
                {{ getFormatDate(qualifica.data_dec) }}
              </div></span
            >
            <span v-if="!qualifica.data_dec"> - </span>
          </div>
        </template>
        <template v-slot:cell-data_fine="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <span v-if="qualifica.data_fine">
              {{ getFormatDate(qualifica.data_fine) }}</span
            >
            <span v-if="!qualifica.data_fine"> - </span>
          </div>
        </template>
        <template v-slot:cell-R="{ row: data }">
          <div class="d-flex" v-if="data.RU">
            <i
              :class="
                data.R
                  ? 'bi bi-check-lg fw-bold text-success fs-2'
                  : 'bi bi-x-lg fw-bold text-danger'
              "
            ></i>
            <Popper
              arrow
              content="
               Tecnico in regola forzata"
            >
              <button
                v-show="data.R && data.RU"
                class="bi bi-exclamation-circle text-center text-gray-700"
                style="margin-left: -3px"
              ></button>
            </Popper>
          </div>
          <i
            v-show="!data.RU"
            :class="
              data.R
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i>
        </template>
        <template v-slot:cell-T="{ row: data }">
          <i
            :class="
              data.T
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i>
        </template>
        <template v-slot:cell-aggiornamento="{ row: data }">
          <template
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <div
              class="d-flex py-1 align-items-center justify-content-center"
              v-if="
                qualifica.albo != 'UDG' &&
                (qualifica.in_regola_aggiornamento_prec == 1 ||
                  qualifica.in_regola_aggiornamento_prec == 0)
              "
            >
              <i
                :class="
                  qualifica.in_regola_aggiornamento_prec == 1
                    ? 'bi bi-check-lg fw-bold text-success fs-2'
                    : qualifica.in_regola_aggiornamento_prec == 0
                    ? 'bi bi-x-lg fw-bold text-danger p-1'
                    : ''
                "
              ></i>
            </div>
            <div v-else class="text-center py-1">-</div>
          </template>
        </template>
        <template v-slot:cell-aggiornamento_2425="{ row: data }">
          <template
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <div
              class="d-flex py-1 align-items-center justify-content-center"
              v-if="
                qualifica.albo != 'UDG' &&
                (qualifica.in_regola_aggiornamento == 1 ||
                  qualifica.in_regola_aggiornamento == 0)
              "
            >
              <i
                :class="
                  qualifica.in_regola_aggiornamento == 1
                    ? 'bi bi-check-lg fw-bold text-success fs-2'
                    : qualifica.in_regola_aggiornamento == 0
                    ? 'bi bi-x-lg fw-bold text-danger p-1'
                    : ''
                "
              ></i>
            </div>
            <div v-else class="text-center py-1">-</div>
          </template>
        </template>

        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              @click="
                getCorsiAggiornamentoSnm(data.id_persona);
                selectedPersona = data.nominativo_completo;
              "
            >
              <a
                href="#"
                class="navi-link"
                data-bs-toggle="modal"
                data-bs-target="#corsi_aggiornamento_snm"
              >
                <i class="bi bi-bookmark-check text-dark fs-5"></i>
              </a>
            </button>
            <button
              v-if="!readOnly"
              class="btn btn-sm dropdown p-1 ps-2"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_select_edit_albo"
              @click="
                listQualifiche(data.id_persona);
                selectedPersona = data.nominativo_completo;
              "
            >
              <i class="bi bi-pen text-dark fs-5"></i>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <router-link
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-eye text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Visualizza anagrafica</span
                      >
                    </div>
                  </div>
                </li></router-link
              >
              <a
                href="#"
                class="navi-link"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_delete_qualifiche"
                @click="
                  listQualifiche(data.id_persona, 'delete');
                  selectedPersona = data.nominativo_completo;
                "
              >
                <li role="presentation" class="navi-item">
                  <div
                    class="b-dropdown-text text-start py-2 px-4"
                    v-if="!readOnly"
                  >
                    <div>
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"
                        >Cancella qualifica</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <!-- <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <a
                    href="#"
                    class="navi-link"
                    @click="promuoviMaestroNew(data.id_persona)"
                  >
                    <i class="bi bi-chevron-up text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Promuovi a maestro</span>
                  </a>
                </div>
              </li> -->
              <!-- <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <a
                    href="#"
                    class="navi-link"
                    @click="addSgat(data.id_persona)"
                  >
                    <i class="bi bi-shield-lock text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7">
                      Assegna permessi SGAT</span
                    >
                  </a>
                </div>
              </li> -->
              <a
                href="#"
                v-if="!data.R && !data.RU"
                @click="inRegolaUfficio(data.id_persona, 'true', 'ON')"
              >
                <li role="presentation" class="navi-item">
                  <div
                    class="b-dropdown-text text-start py-2 px-4"
                    v-if="!readOnly"
                  >
                    <div class="navi-link">
                      <i class="bi bi-toggle-on text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        In regola d'ufficio ON</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.R && data.RU"
                @click="inRegolaUfficio(data.id_persona, 'false', 'OFF')"
              >
                <li role="presentation" class="navi-item">
                  <div
                    class="b-dropdown-text text-start py-2 px-4"
                    v-if="!readOnly"
                  >
                    <div class="navi-link">
                      <i class="bi bi-toggle-off text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        In regola d'ufficio OFF</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.can_subscribe && data.utenza_snm_o_dir"
                data-bs-toggle="modal"
                data-bs-target="#modal_add_utenza_albo"
                @click="personaUtenza = data"
              >
                <li role="presentation" class="navi-item">
                  <div
                    class="b-dropdown-text text-start py-2 px-4"
                    v-if="!readOnly"
                  >
                    <span class="navi-link">
                      <i class="bi bi-person-plus text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Crea utenza online</span
                      >
                    </span>
                  </div>
                </li></a
              >
              <router-link
                :to="{
                  name: 'bacheca-insegnante-snm',
                  params: {
                    id_persona: data.id_persona,
                  },
                }"
                v-if="data.utenza_snm_o_dir"
              >
                <li role="presentation" class="navi-item">
                  <div
                    class="b-dropdown-text text-start py-2 px-4"
                    v-if="!readOnly"
                  >
                    <div class="navi-link">
                      <i class="bi bi-person-workspace text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Opera come questa persona</span
                      >
                    </div>
                  </div>
                </li></router-link
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <SelectEditElemento
      :albo="selectedAlbo"
      :persona="selectedPersona"
      :isLoadingDetail="isLoadingDetail"
    />
    <DeleteQualifiche
      :albo="selectedAlbo"
      :persona="selectedPersona"
      @refreshAlboList="refreshAlboList"
      :isLoadingDetail="isLoadingDetail"
    />
    <AddUtenzaAlbo
      :persona="personaUtenza"
      @refreshAlboList="refreshAlboList"
    />
    <ListaCorsiAggiornamentoSnm
      :nominativo_persona="selectedPersona"
      :corsi_aggiornamento_snm_list="corsi_aggiornamento_snm_list"
      :loadingDetail="loadingDetail"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import AddAlbo from "./AddAlbo.vue";
import AddUtenzaAlbo from "./AddUtenzaAlbo.vue";
import SelectEditElemento from "./SelectEditElemento.vue";
import DeleteQualifiche from "./DeleteQualifiche.vue";
import ModalEsportaAlbo from "./ModalEsportaAlbo.vue";
import { viewCorsiAggiornamentoList } from "@/requests/alboInsegnateRequests";
import {
  editListAlbo,
  promuoviAMaestro,
  inRegolaOnOff,
} from "@/requests/alboRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { alertFailed, alertSuccess } from "@/composables/swAlert";

import Popper from "vue3-popper";

import ListaCorsiAggiornamentoSnm from "@/components/components-fit/SNM/albo-snm/ListaCorsiAggiornamentoSnm.vue";

export default {
  name: "TableAlbo",
  components: {
    Datatable,
    AddAlbo,
    SelectEditElemento,
    DeleteQualifiche,
    Loading,
    Popper,
    AddUtenzaAlbo,
    ModalEsportaAlbo,
    ListaCorsiAggiornamentoSnm,
  },
  emits: ["getAlboList", "resetFilters"],
  setup(props, { emit }) {
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const store = useStore();

    const setFetchRowsAlbo = (e) => {
      store.commit("setFetchRowsAlbo", e);
      emit("getAlboList");
    };
    const setCurrentPageAlbo = (page) => {
      store.commit("setCurrentPageAlbo", page);
      emit("getAlboList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAlbo", columnName);
      store.commit("setSortOrderAlbo", order);
      emit("getAlboList");
    };

    const refreshAlboList = () => {
      emit("getAlboList");
    };

    const isLoadingDetail = ref(false);

    const selectedAlbo = ref({});
    const selectedPersona = ref("");
    const listQualifiche = (id_Persona, from) => {
      isLoadingDetail.value = true;
      selectedAlbo.value = {};
      editListAlbo(id_Persona).then((res) => {
        isLoadingDetail.value = false;
        if (res.length > 0) {
          selectedAlbo.value = res;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            from == "delete"
              ? "kt_modal_delete_qualifiche"
              : "kt_modal_select_edit_albo"
          );
        }
      });
    };

    const isLoading = ref(false);

    /* const addSgat = (id_Persona) => {
      isLoading.value = true;
      addPermessiSgat(id_Persona).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          alertSuccess("Permessi SGAT assegnati");
          emit("getDocumentiSocieta");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    }; */

    const promuoviMaestroNew = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesserato verrà promosso maestro.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Promuovi",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await promuoviAMaestro(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Promozione maestro eseguita correttamente");
              emit("getAlboList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    // const promuoviMaestro = (id_Persona) => {
    //   isLoading.value = true;
    //   promuoviAMaestro(id_Persona).then((res) => {
    //     isLoading.value = false;
    //     if (res.status == 200) {
    //       alertSuccess("Promozione avvenuta con successo");
    //       emit("getAlboList");
    //     } else {
    //       alertFailed(
    //         res.data.message
    //           ? res.data.message
    //           : "Attenzione! Si è verificato un errore. Riprovare più tardi"
    //       );
    //     }
    //   });
    // };

    const inRegolaUfficio = (id_persona, azione, in_regola) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesserato verrà settato in regola d'ufficio: " + in_regola,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await inRegolaOnOff(id_persona, azione).then((res) => {
            if (res.status == 200) {
              alertSuccess("Modifica effettuata correttamente");
              emit("getAlboList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const personaUtenza = ref(null);

    const loadingDetail = ref(false);
    const corsi_aggiornamento_snm_list = ref(null);
    const getCorsiAggiornamentoSnm = (id_persona) => {
      loadingDetail.value = true;
      viewCorsiAggiornamentoList(id_persona).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          corsi_aggiornamento_snm_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "corsi_aggiornamento_snm"
          );
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Persona",
        key: "nominativo_completo",
      },
      {
        name: "Cod. SNM",
        key: "codice_snm",
        sortable: false,
      },
      {
        name: "Cod. DIR",
        key: "codice_dir",
        sortable: false,
      },
      {
        name: "N. Albo",
        key: "numero_albo",
        sortable: false,
      },
      {
        name: "Tipo Albo",
        key: "tipo_albo",
        sortable: false,
      },
      {
        name: "Categoria / Qualifica / Livello",
        key: "categoria_qualifica",
        sortable: false,
      },
      {
        name: "Data acq.",
        key: "data_acq",
        align: "center",
      },
      {
        name: "Data dec.",
        key: "data_decorrenza",
        sortable: false,
        align: "center",
      },
      {
        name: "Data fine",
        key: "data_fine",
        sortable: false,
      },
      {
        name: "R",
        key: "R",
        sortable: false,
      },
      {
        name: "T",
        key: "T",
        sortable: false,
      },
      {
        name: "Agg. 22/23",
        key: "aggiornamento",
        sortable: false,
      },
      {
        name: "Agg. 24/25",
        key: "aggiornamento_2425",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      getFormatDate,
      albo_list: computed(() =>
        store.getters.getStateFromName("resultsalbo_list")
      ),
      loaded: computed(() => store.getters.getStateFromName("loadedalbo_list")),
      record: computed(() => store.getters.getStateFromName("recordalbo_list")),
      status: computed(() => store.getters.getStateFromName("statusalbo_list")),
      refreshAlboList,
      currentPage: computed(() => store.getters.currentPageAlbo),
      rowsToSkip: computed(() => store.getters.rowsToSkipAlbo),
      fetchRows: computed(() => store.getters.fetchRowsAlbo),
      sortColumn: computed(() => store.getters.sortColumnAlbo),
      sortOrder: computed(() => store.getters.sortOrderAlbo),
      setFetchRowsAlbo,
      setCurrentPageAlbo,
      setSortOrderColumn,
      listQualifiche,
      tableHeader,
      selectedAlbo,
      personaUtenza,
      selectedPersona,
      //addSgat,
      getCorsiAggiornamentoSnm,
      promuoviMaestroNew,
      inRegolaUfficio,
      isLoading,
      isLoadingDetail,
      loadingDetail,
      corsi_aggiornamento_snm_list,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
